import React from 'react'
import NavBar from './NavBar';
import DownSection from './DownSection';

export default function LayoutPage() {

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div>
                <DownSection />
            </div>
        </>
    )
}
