import React from "react";
import "./Landing.css";
import { useNavigate } from "react-router-dom";

const OurClients = () => {
  const navigate = useNavigate();

  const navigatetoContact = () => {
    navigate("/contact");
  };

  return (
    <>
      <div className="clientPageforWeb">
        <div className="col-12 pt-5 vh-100 Clientpg">
          <div className="text-center">
            <img
              src="./Images/gobeyond/Our-Client-Icon.svg"
              width="150"
              height="50"
            />
            <h2 className="col-12 mb-3 mt-2 clientPgHeading">
              Fueling Innovation Through Collaborative Excellence
            </h2>
            <span className="col-12 mb-0 mt-2 clientPgPara">
              Powering the best teams, from next-generation startups to
              establish enterprises.
            </span>
          </div>
          <div className="ClientpgImgs">
            <div className="mdbcardMain">
              <div className="d-flex py-3 px-2 GapForClient">
                <div className="card shadow ClientPgCard">
                  <img src="./Images/gobeyond/Atcs.png" className="ATCSIMG" />
                </div>

                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/brand/PhaseZeroLogo.png"
                    alt="..."
                    className="InfioIMG"
                  />
                </div>

                <div className="card shadow ClientPgCard">
                  <img src="./Images/gobeyond/Group 427321539.svg" />
                </div>

                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/brand/InfionicLogo.png"
                    className="InfioIMG"
                    alt="..."
                  />
                </div>

                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/gobeyond/TEAM15919945.png"
                    className="ITUSIMG"
                  />
                </div>
              </div>

              <div className="d-flex px-2 py-1 GapForClient">
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/brand/InsurFinLogo.png"
                    className="InsureIMG"
                    alt="..."
                  />
                </div>
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/gobeyond/AnimotionLogo_final.png"
                    width="45%"
                  />
                </div>
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/gobeyond/FineKonLogo.png"
                    className="InsureIMG"
                    alt="FineKon Logo"
                  />
                </div>
                <div className="card shadow ClientPgCard">
                  <img src="./Images/gobeyond/Group4273215.png" width="75%" />
                </div>
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/brand/InfinityLearnLogo.png"
                    className="InsureIMG"
                    alt="..."
                  />
                </div>
              </div>
              <div className="mt-5">and many more...</div>
            </div>

            {/* <div className='col-12 text-center tryusbtn'>
                        <button type='button' className='btn btn-primary' onClick={navigatetoContact}>Try us out</button>
                    </div> */}
          </div>
        </div>
      </div>

      {/* for mobile ------------------------------------------------------------ */}
      <div className="clientPageforMobile">
        <div className="col-lg-7 pt-4 Clientpg">
          <div className="text-center">
            <img
              src="./Images/gobeyond/Our-Client-Icon.svg"
              width="150"
              height="50"
            />
            <h2 className="mb-2 mt-2 clientPgHeading">
              Fueling Innovation Through Collaborative Excellence
            </h2>
            <span className="mb-4 text-left clientPgPara">
              Powering the best teams, from next-generation startups to
              establish enterprises.
            </span>
          </div>
          <div className="ClientpgImgs">
            <div className="mdbcardMain">
              <div className="d-flex py-1 px-2 GapForClient">
                <div className="card shadow ClientPgCard p-2">
                  <img src="./Images/gobeyond/Atcs.png" className="ATCSIMG"
                    width="100%"
                    />
                </div>

                <div className="card shadow ClientPgCard p-2">
                  <img
                    src="./Images/brand/PhaseZeroLogo.png"
                    alt="..."
                    width="100%"
                    className="InfioIMG"
                  />
                </div>

                <div className="card shadow ClientPgCard p-2">
                  <img src="./Images/gobeyond/Group 427321539.svg" 
                    width="100%"
                    />
                </div>

              </div>

              <div className="d-flex px-2 py-1 GapForClient px-2">
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/brand/InfionicLogo.png"
                    className="InfioIMG"
                    width="100%"
                    alt="..."
                  />
                </div>
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/gobeyond/TEAM15919945.png"
                    width="100%"
                    className="ITUSIMG"
                  />
                </div>

                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/brand/InsurFinLogo.png"
                    className="InsureIMG"
                    width="100%"
                    alt="..."
                  />
                </div>
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/gobeyond/AnimotionLogo_final.png"
                    width="75%"
                  />
                </div>
              </div>
              <div className="d-flex px-2 py-1 GapForClient">
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/gobeyond/FineKonLogo.png"
                    className="InsureIMG"
                    alt="FineKon Logo"
                    width="100%"
                  />
                </div>
                <div className="card shadow ClientPgCard">
                  <img src="./Images/gobeyond/Group4273215.png" width="75%" />
                </div>
                <div className="card shadow ClientPgCard">
                  <img
                    src="./Images/brand/InfinityLearnLogo.png"
                    className="InsureIMG"
                    alt="..."
                    width="100%"
                  />
                </div>
              </div>

              <div className="mt-2 mb-4 text-center">and many more...</div>
            </div>

            {/* <div className='col-12 text-center tryusbtn'>
                        <button type='button' className='btn btn-primary' onClick={navigatetoContact}>Try us out</button>
                    </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurClients;
